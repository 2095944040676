import {Component, NgZone, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import { Emailtemplate, Settings} from '../../models/settings';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../core/Setting.Service';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {SYS_APPT_EMAIL_TEMPL, SYS_PASS_RESET_TEMPL} from '../../models/constant';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
  providers: [MessageService]
})
export class EmailTemplateComponent implements OnInit {

  model: Settings = new Settings();
  modelPass: Settings = new Settings();
  emailTemp: Emailtemplate = new Emailtemplate();
  emailTempPass: Emailtemplate = new Emailtemplate();

  selectedPackage = 0;
  constructor( private zone: NgZone,  private router: Router,
               private route: ActivatedRoute,
               private  apiService: SettingsService,
               private messageService: MessageService, private msgService: SystemMessagingService) {

  }

  async ngOnInit() {
    this.model.keyName = SYS_APPT_EMAIL_TEMPL;
    this.model.keyValue = '';
    this.emailTemp.EmailSubject = '';
    this.emailTemp.EmailContent = '';

    this.modelPass.keyName = SYS_PASS_RESET_TEMPL;
    this.modelPass.keyValue = '';
    this.emailTempPass.EmailSubject = '';
    this.emailTempPass.EmailContent = '';





    this.doRealInit();

  }

  doRealInit( ) {

    this.apiService.get( SYS_APPT_EMAIL_TEMPL ).then( result => {
      this.model = result;
      if ( this.model.keyValue.length > 0 ) {
        this.emailTemp = JSON.parse(this.model.keyValue);
      }
      this.model.keyName = SYS_APPT_EMAIL_TEMPL;
    });

    this.apiService.get( SYS_PASS_RESET_TEMPL ).then( result => {
      this.modelPass = result;
      if ( this.modelPass.keyValue.length > 0 ) {
        this.emailTempPass = JSON.parse(this.modelPass.keyValue);
      }
      this.modelPass.keyName = SYS_PASS_RESET_TEMPL;
    });
  }

  async onSubmit() {

    // this.model.clientPackage = this.selectedPackage;

    this.model.keyValue = JSON.stringify(this.emailTemp );

    const res = await this.apiService.update(this.model);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));

  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }

  async onSubmitPass() {
    // this.model.clientPackage = this.selectedPackage;

    this.modelPass.keyValue = JSON.stringify(this.emailTempPass );

    const res = await this.apiService.update(this.modelPass);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));

  }
}
