
export class Settings {
    keyName: string;
    keyValue: string;
}


export class Lookups {
    id: number;
    keyType: string;
    keyName: string;
    keyValue: string;
}


export class Emailtemplate {
    EmailSubject: string;
    EmailContent: string;
}



export class EmailConfig {
    FromName: string;
    FromAddress: string;
    MailServerAddress: string;
    MailServerPort: number;
    UserPassword: string;
    EmailSubject: string;
}


export class DashbordCard {
    name: string;
    nameCount: number;
}
