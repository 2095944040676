import {Component, NgZone, OnInit} from '@angular/core';
import {EmailConfig, Settings} from '../../models/settings';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../core/Setting.Service';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {SYS_EMAIL_SETTINGS} from '../../models/constant';
import {DepartmentService} from '../../core/department.service';
import {Department} from '../../models/Department';

@Component({
  selector: 'app-appointment-config',
  templateUrl: './appointment-config.component.html',
  styleUrls: ['./appointment-config.component.scss'],
  providers: [MessageService]
})
export class AppointmentConfigComponent implements OnInit {

  en: any;

  model: Department = new Department();
  disabledDayOfWeek: Array<number> = [];
  checkBoxesDayOfWeek: Array<string> = [];

  disabledDates: Array<Date> = [];
  minDate = new Date( Date.now() );
  maxDate = new Date( Date.now() );



  pageTitle = 'اعدادات الحجوزات';
  constructor( private zone: NgZone,  private router: Router,
               private route: ActivatedRoute,
               private  apiService: DepartmentService,
               private messageService: MessageService, private msgService: SystemMessagingService) {

  }

  async ngOnInit() {
    this.model.id = 0;
    this.setLocale();
    this.doRealInit();

  }

  doRealInit( ) {

    this.apiService.get( 1 ).then( result => {
      this.model = result;
      this.disabledDayOfWeek = [];
      this.checkBoxesDayOfWeek = [];
      this.disabledDates = [];

     // this.disabledDayOfWeek.push( this.model.blockedDayOfWeek);

      this.model.blockedDayOfWeek.forEach( day => {
        this.checkBoxesDayOfWeek.push( day.toString() );
      });

      this.model.blockedDayOfWeek.forEach( day => {
        this.disabledDayOfWeek.push( day );
      });

      this.model.blockedDatesList.forEach( dItem => {
        this.disabledDates.push( new Date( dItem ));
      });

      this.maxDate = this.addDays( this.minDate, this.model.allowedBookingPeriod);

    });
  }

  async onSubmit() {

    this.model.depName = 'default';
    // this.model.blockedDayOfWeek = parseInt( this.model.blockedDayOfWeek.toString(), 10 );

    this.disabledDayOfWeek = [];
    this.model.blockedDayOfWeek = [];
    this.checkBoxesDayOfWeek.forEach( day => {
      this.model.blockedDayOfWeek.push( parseInt( day.toString(), 10 ));
    });
    this.cleanUpDisabledDates();
    const res = await this.apiService.update(this.model);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));

  }
  cleanUpDisabledDates() {

    this.model.blockedDatesList = [];
    this.disabledDates.forEach( ddate => {
      this.model.blockedDatesList.push( ddate );
    });

  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }


  onDaySelected($event: any) {

   // this.disabledDates.push( $event);
  }



  onBlockedWeekDay_Sunday($event: any) { this.onBlockedWeekDay( 0, $event.checked ); }
  onBlockedWeekDay_Monday($event: any) { this.onBlockedWeekDay( 1, $event.checked ); }
  onBlockedWeekDay_Tusday($event: any) { this.onBlockedWeekDay( 2, $event.checked ); }
  onBlockedWeekDay_Wednesday($event: any) { this.onBlockedWeekDay( 3, $event.checked ); }
  onBlockedWeekDay_Thursday($event: any) {this.onBlockedWeekDay( 4, $event.checked ); }
  onBlockedWeekDay_Friday($event: any) { this.onBlockedWeekDay( 5, $event.checked ); }
  onBlockedWeekDay_Saturday($event: any) { this.onBlockedWeekDay( 6, $event.checked ); }

  onBlockedWeekDay( dayOfTheWeek: number, isChecked: boolean) {

   // this.checkBoxesDayOfWeek[ dayOfTheWeek] = isChecked === true ? '1' : '0';
    this.disabledDayOfWeek = [];
    this.checkBoxesDayOfWeek.forEach( day => {
      this.disabledDayOfWeek.push( parseInt( day.toString(), 10 ));
    });
  }


  onAllowedPeriodChanged($event: any) {

    this.maxDate = this.addDays( this.minDate, this.model.allowedBookingPeriod);
  }
  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  setLocale() {
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesShort: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesMin: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      monthNames: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      monthNamesShort: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    };


  }
}
