import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem, MessageService, TreeNode} from 'primeng/api';
import {Place} from '../../models/Places';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {LocationService} from '../../core/location.service';
import {GeneralTransfer} from '../../models/GeneralTransfer';
import {Tree} from 'primeng/tree';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  providers: [MessageService]
})
export class LocationComponent implements OnInit {

  @ViewChild('tree', { static: true }) placeTree: Tree;

  nodes: TreeNode[] = [];
  items: MenuItem[];
  selectedNode: TreeNode;
  deleteDisplay = false;
  updateDisplay = false;
  selectedEntry = '';
  constructor(
      private apiService: LocationService,
      private messageService: MessageService,
      private msgService: SystemMessagingService
  ) { }

  async ngOnInit() {

    await this.fillNodes();

    this.items = [
      {label: ' إضافة', icon: 'pi pi-plus', command: (event) => this.addNode( this.selectedNode )},
      {label: ' تعديل', icon: 'pi pi-times', command: (event) =>  this.editNode( this.selectedNode )},
      {label:  ' حذف', icon: 'pi pi-times', command: (event) =>  this.deleteNode( this.selectedNode )},
    ];
  }

  async fillNodes() {

    /* const place = new Place();
    place.locType = 0;
    place.id = 0;
    place.locName = 'مناطق';
    const root: any = {label: ' المناطق',
                        key: 0,
                        expandedIcon: 'pi pi-folder-open',
                        collapsedIcon: 'pi pi-folder', data: place, children: []  };
    this.nodes.push( root );
    */
    const result: any = await this.apiService.getTree( );
    this.nodes.push( result);
    this.placeTree.getRootNode()[0].expanded = true;
  }

  async addNode( selectedNode: TreeNode ){

    const newplace = new Place();
    newplace.locType = 0;
    newplace.id = 0;
    newplace.locName = 'مناطق';

    const temp: any = {label: 'جديد', key: 0, expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', data: newplace, children: [] };
    const place: Place = selectedNode.data as Place;
    if ( place.locType > 2 ) {
      this.showMessage('error', this.msgService.getMessage ( 'MSG_LOCATION_ERROR_ADDING' ));
      return;
    }

    if ( place.locType === 0 ) {
      temp.label = 'محافظة جديدة';
      newplace.locName = 'محافظة جديدة';
      newplace.locType = 1;
      newplace.parentId = place.id;

    }
    if ( place.locType === 1 ) {
      temp.label = 'قضاء جديد';
      newplace.locName = 'قضاء جديد';
      newplace.locType = 2;
      newplace.parentId = place.id;
    }
    if ( place.locType === 2 ) {
      temp.label = 'ناحية جديدة';
      newplace.locName = 'ناحية جديدة';
      newplace.locType = 3;
      newplace.parentId = place.id;
    }


    const result: GeneralTransfer = await this.apiService.add( newplace );
    newplace.id = Number( result.message );
    temp.key = newplace.id;

    selectedNode.children.push( temp );
    selectedNode.expanded = true;

  }
  editNode( selectedNode: TreeNode ){

    const place: Place = selectedNode.data as Place;
    if ( place.locType === 0 ) {
      this.showMessage('error', this.msgService.getMessage ( 'MSG_LOCATION_ERROR_NODE_NO_UPDATE' ));
      return;
    }

    this.updateDisplay = true;
    this.selectedEntry = this.selectedNode.label;

  }
  deleteNode( selectedNode: TreeNode ){
    const place: Place = selectedNode.data as Place;
    if ( selectedNode.children.length > 0 || place.locType === 0  ) {
      this.showMessage('error', this.msgService.getMessage ( 'MSG_LOCATION_ERROR_NODE_HAS_CHILDREN' ));
      return;
    }
    this.deleteDisplay = true;
    this.selectedEntry = this.selectedNode.label;

  }


  onConfirmDelete($event: MouseEvent) {

    const node = this.selectedNode.parent;
    node.children = node.children.filter( n => n !== this.selectedNode );
    this.deleteDisplay = false;
  }

  async onConfirmUpdate($event: MouseEvent) {

    this.updateDisplay = false;
    this.selectedNode.label = this.selectedEntry;
    const place: Place = this.selectedNode.data as Place;
    place.locName = this.selectedEntry;
    const result: GeneralTransfer = await this.apiService.update( place );

  }


  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }

}
