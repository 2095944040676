import {Component, NgZone, OnInit} from '@angular/core';
import {SYS_EMAIL_SETTINGS} from '../../models/constant';
import {EmailConfig, Settings} from '../../models/settings';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {SettingsService} from '../../core/Setting.Service';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss'],
  providers: [MessageService]
})
export class EmailSettingComponent implements OnInit {

  model: Settings = new Settings();
  emailInfo: EmailConfig = new EmailConfig();

  selectedPackage = 0;
  pageTitle = 'بريد النظام';
  constructor( private zone: NgZone,  private router: Router,
               private route: ActivatedRoute,
               private  apiService: SettingsService,
               private messageService: MessageService, private msgService: SystemMessagingService) {

  }

  async ngOnInit() {
    this.model.keyName = SYS_EMAIL_SETTINGS;
    this.emailInfo.EmailSubject = '';
    this.emailInfo.FromAddress = '';
    this.emailInfo.FromName = '';
    this.emailInfo.MailServerAddress = '';
    this.emailInfo.MailServerPort = 1;
    this.emailInfo.UserPassword = '';
    this.doRealInit();

  }

  doRealInit( ) {

    this.apiService.get( SYS_EMAIL_SETTINGS ).then( result => {
      this.model = result;
      this.emailInfo = JSON.parse(this.model.keyValue );
      // this.selectedPackage = this.model.clientPackage;

    });
  }

  async onSubmit() {

    // this.model.clientPackage = this.selectedPackage;

    this.model.keyValue = JSON.stringify(this.emailInfo );
    this.model.keyName = SYS_EMAIL_SETTINGS;

    const res = await this.apiService.update(this.model);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));

  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }


}
