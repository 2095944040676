import {Component, NgZone, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {Lookups} from '../../models/settings';
import {LookupService} from '../../core/lookup.service';
import {LOOKUP_APPLICATION_TYPE, LOOKUP_CITIZEN_TYPE} from '../../models/constant';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  providers: [MessageService]

})
export class LookupComponent implements OnInit {

  citizenType: Lookups[] = [];
  applicationType: Lookups[] = [];
  addressType: Lookups[] = [];
  currentObject: Lookups = new Lookups();

  addEditDisplay = false;

  deleteDisplay = false;
  deleteMessage = 'هل انت متأكد من حذف هذا الموضوع؟';
  AddEditTitle = 'إضافة';
  selectedId = 0;
  selectedWord = '';
  selectedLookupType = '';
  cols: any[];

  pageSize = 10;

  constructor(private zone: NgZone,  private router: Router,
              private route: ActivatedRoute,
              private  apiLookupService: LookupService,
              private messageService: MessageService,
              private msgService: SystemMessagingService) { }

  async ngOnInit() {

    this.getAll();
  }

  getAll() {
    this.apiLookupService.getList().then( result => {

      result.forEach( item => {
        if ( item.keyType === LOOKUP_CITIZEN_TYPE ) {
          this.citizenType.push(item);
        }
        if ( item.keyType === LOOKUP_APPLICATION_TYPE ) {
          this.applicationType.push(item);
        }
      });
    });
  }

  getByType( typeId: string ) {

    this.apiLookupService.getListByType( typeId ).then( result => {

      if ( typeId === LOOKUP_CITIZEN_TYPE) {
        this.citizenType = [];
      }
      if ( typeId === LOOKUP_APPLICATION_TYPE) {
        this.applicationType = [];
      }
      result.forEach( item => {
        if ( item.keyType === LOOKUP_CITIZEN_TYPE ) {
          this.citizenType.push(item);
        }
        if ( item.keyType === LOOKUP_APPLICATION_TYPE ) {
          this.applicationType.push(item);
        }
      });
    });
  }

  getSearchFields(): any {

    const fields: any = {};
    if ( this.selectedWord !== null && this.selectedWord.length > 0  )  {
      fields[ 'depname' ] = this.selectedWord;
    }
    return fields;

  }



  onEdit(rowData: Lookups ) {
    this.AddEditTitle = 'تعديل';
    this.currentObject = new Lookups();
    this.currentObject.keyType = rowData.keyType;
    this.currentObject.keyName = rowData.keyName;
    this.currentObject.keyValue = rowData.keyValue;
    this.currentObject.id = rowData.id;
    this.addEditDisplay = true;
  }

  onDelete( data: Lookups ) {
    this.selectedId = data.id;
    this.selectedWord = data.keyName;
    this.selectedLookupType = data.keyType;

    this.deleteDisplay = true;
  }


  async onConfirmDelete( e: any ) {

    this.deleteDisplay = false;
    if ( e === false ) {
      return;
    }

    this.messageService.clear();
    this.selectedWord = '';


    const res = await this.apiLookupService.delete( this.selectedId);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));
    this.getByType( this.selectedLookupType );
    this.deleteDisplay = false;

  }

  onAddEdit() {

    if ( this.currentObject.id === 0  ) {
      this.apiLookupService.add(this.currentObject).then(result => {
        this.showMessage(result.isOK === true ? 'success' : 'error', this.msgService.getMessage(result.message));
        this.getByType( this.currentObject.keyType );
      });
    } else {
      this.apiLookupService.update(this.currentObject).then(result => {
        this.showMessage(result.isOK === true ? 'success' : 'error', this.msgService.getMessage(result.message));
        this.getByType( this.currentObject.keyType );
      });
    }
    this.addEditDisplay = false;
  }

  onAdd( lookupType: string  ) {

    this.AddEditTitle = 'اضافة';
    this.currentObject = new Lookups();
    this.currentObject.keyType = lookupType;
    this.currentObject.keyName = '';
    this.currentObject.keyValue = '';
    this.currentObject.id = 0;
    this.addEditDisplay = true;
  }


  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }

}
