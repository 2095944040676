
export class Department {
    id: number;
    depName: string;
    maxVisitorPerDay: number;
    allowedBookingPeriod: number;
    blockedDayOfWeek: Array<number>;
    blockedDatesList: Array<Date>;
    isDisabled: boolean;
    isSelected: boolean;
}
