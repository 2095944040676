import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import {FormsModule} from '@angular/forms';
import {PrimeNGModule} from '../primeng.module';
import {CoreModule} from '../core/core.module';
import {UtilitesModule} from '../utilites/utilites.module';
import {RouterModule, Routes} from '@angular/router';
import { EmailSettingComponent } from './email-setting/email-setting.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { LookupComponent } from './lookup/lookup.component';
import { LocationComponent } from './location/location.component';
import { AppointmentConfigComponent } from './appointment-config/appointment-config.component';

const routes: Routes = [
  {path: '', component: EditorComponent},
  {path: 'email-info', component: EmailSettingComponent},
  {path: 'email-template', component: EmailTemplateComponent},
  {path: 'lists', component: LookupComponent},
  {path: 'locations', component: LocationComponent},
  {path: 'appt-config', component: AppointmentConfigComponent}
  ];

@NgModule({
  declarations: [
      EditorComponent,
      EmailSettingComponent,
      EmailSettingComponent,
      EmailTemplateComponent,
      LookupComponent,
      LocationComponent,
      AppointmentConfigComponent],
  imports: [
    CommonModule,
    FormsModule,
    PrimeNGModule,
   // CoreModule,
    UtilitesModule,
    RouterModule.forChild(routes)

  ]
})
export class SettingsModule { }
